@import "lib";

.mobile {
  display: none;   
} 

.mobileonly {
   display: none;   
}    

@media (min-width: 1300px) {
    .container {   
        width: 1280px;   
    }
    
    .app {
      .weeks {
        margin-left:25%;
      }      
    }    
}

@media (max-width: 980px) {
    
    .desktop {
      display:none;   
    }    
    
    .mobile {
      display: block;   
    } 
    
    .mobileonly {
       display:none;    
    }    
 
 body.app {
 background:#5f4595 none;
 background-repeat: no-repeat;
background-size: cover;
}    
    
  header .logo {
    float:right;   
  }  
  
  header nav {
    display:none;   
  }  
  
  .outerwrap header .notificationsandprofile {
    float: right;
    padding: 10px 0 20px 0;
}
  
  .colright {
    position: absolute;
    top:180px;
    left:0;
    width:auto;
    left:50%;
    margin-left:-210px;
  } 
  
  .colrightlogin {
     margin-left:-150px;
  }
    
  .information .countdown {
    margin-top: 160px;
    text-align: center;
    margin-left:0;
  }
  
  .information #clock {
    width: 250px;
    margin:auto;
  }
  
  .information .login ol li,
   .information .login p {
    text-align: left;
}
  
  .primarycontent h1, .popcontent .content h1 {
    padding-top: 180px;
}

.appcontent .box ul li {
   font-size:11px;   
}  

.appcontent .newsfeed {
   margin-top:20px;   
}   

.appcontent .fitterchoice {
    margin-left: 0%;
    overflow: hidden;
    margin-top: 30px;
}

.appcontent .weeks {
    margin-left: 0%; 
}

.fiterchoicewrap .bx-wrapper .bx-next {
    right: 365px;
    
}

.fiterchoicewrap .bx-wrapper .bx-prev {
    left: 25px;
}

.appcontent .knowmore .col-md-3 {    
    width: 50%; 
}

.appcontent .faq {
 
   margin-right: 0px; 
}

.appcontent .query .queryon h3 {
    font-weight: 800;
    font-size: 25px;
    padding: 20px 0 20px 20px;
    color: #fff;
    text-transform: uppercase;
}

.appcontent .query .queryon {
    background: #5a4484;
    float: none;
    width: 100%;
    padding-bottom: 10px;
}

.appcontent .query .queryform {
    width: 100%;
    float: none;
    background: #fff;
}


.appcontent .fiterchoicewrap .tasks h2 {
    margin-left:0;
}

.appcontent .tasks ul, .appcontent .quizzes ul {
   padding-left:0 !important;   
   margin-left:0;
}    

.appcontent h1 {
    font-size: 25px;    
}

.rowstretch {
  margin-right:-10px !important;   
}   


.appcontent .gamevault .gamevaultcontent {
    background: #fff;
    padding: 10px 25px;
    min-height: 450px;
    margin-bottom: 20px;
}

.appcontent .leaderboard {
  width:80%;
  margin:auto;
}
.appcontent .leaderboard .profile {
    width: 100%;
    float: none;
    padding-top:25px;
    overflow: hidden;
    .profileimagewrap {
      float:left;
      width:50%;
      background:url(../images/hline-tablet.png);
      background-repeat: no-repeat;
      background-position: right center;
    } 
    .pointswrap {
      float:left;
      width:50%;
      .points {
         margin-top:40px;   
      }
    }    
   
}

.appcontent .leaderboard .toplist {
    clear:both;
   float: none; 
  width: 100%; 
 
}

.appcontent .leaderboard .profile .profileimage {
    margin: 0px auto auto auto;
    overflow: hidden;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    border: 5px solid #fff;
}
  
  footer .row .col-md-12 {
    text-align: center;
    margin-top:100px;
  }      
  
  footer ul {
    float: right; 
    display: inline-block;
    list-style: none;
    margin-top: 30px;
    text-align: center;
 }
 
 .loader.privacy .content {
    width: 95%;
    background: #fff;
    margin: auto;
    height: 500px;
    text-align: left;
}

.appcontent .tasks ul li .task, .appcontent .quizzes ul li .task, .appcontent .tasks ul li .quiz, .appcontent .quizzes ul li .quiz {
  min-height: 240px;   
}   

.appcontent .quizzes ul li .quiz.done,
.appcontent .tasks ul li .task.done{
    min-height: 240px;
}

   

.appcontent .storyboard .fullarticle {
    padding-left: 15px;
    padding-right: 5px;
    position: absolute;
    width: 565px;
}

.appcontent .storyboard .fullarticle span.closepop {
    right:-17%;
}    

.appcontent .storyboard .fullarticle article {
    padding: 0;
    margin-left: 10%;
    margin-right: 10%;
     min-width: 550px; 
}

.appcontent .taskrow .col-md-5 {
    width: 100%;
    margin-left: 0px;
}

.appcontent .score {
  margin:auto;   
} 


.appcontent .galleryalbum .album .albumcover {
    float: none;
    width: 100%;
    img {
      width:100%;
      height:auto;
    }    
}

.appcontent .galleryalbum .album .albumdetails {
  float:none;
  width:100%;
  padding-top:20px;
}    

.appcontent .knowmore .col-md-3 {
    width: 98%;
    margin-bottom: 20px;
}

.appcontent .newsfeed ul {
  padding-left:0;   
}  

.weeklywinnercol {
  background:#fff;
  overflow: hidden;
  margin-bottom: 20px;
  .profile {
      background:url(../images/hline-profile-mobile.png);
      background-repeat: no-repeat;
      background-position: right;
      width:50%;
      float:left;
  } 
  h2 {
   font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;   
    margin-left:20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
  } 
  .profileimage {        
                margin: 0px auto auto auto;
                 overflow: hidden;
                border-radius: 50%;
                width: 65px;
                height: 65px;        
                border: 5px solid #fff;        
                   img {
                    display: inline;
                    margin: 0 auto;
                    height: 100%;
                  } 
           }
            h3 {
            font-weight: 800;
            font-size: 16px;
            text-transform: uppercase;
            margin:8px 0 0 0px;
            padding:0;
            text-align: center;
           
           } 
           .level {
             margin-top:10px;  
             text-align: center;
             margin-bottom: 20px;
             span {
                     background:#615182;  
                   padding:0 10px 2px 10px;
                   -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    border-radius: 8px;
                    color:#fff;
                    font-size: 11px;
                    font-weight: bold;
                  }      
           }  
           .points {
             float:right;
             text-align: center;
             width:50%;
             p {
               font-size: 25px;
               font-weight: 800;
             }   
             .ribbion {
                    background: url(../images/icon-ribbion-black.png);
                    background-repeat: no-repeat;  
                    padding-left: 20px;
                    display: inline-block;
                  
                    p {
                         font-weight: normal;
                    font-size: 13px; 
                    }
             } 
           } 
}    

.appcontent  .mobile .faq {
  margin-left:20px;
  margin-right:20px;
   ul.faq {
       list-style:none;
       margin:0;
       padding:0;
     li {
       padding:15px 20px 10px 30px;   
     } 
     li.q {
        border-bottom: 1px solid #efefef;
        overflow: hidden;
        margin-bottom: 0px;
        span {
          float:left;  
          width:90%;
        }    
        img {
           margin-right:0px;   
           float:right;
           margin-right:10px;
           margin-top:8px;
          
        }    
        img.rotate {
          -moz-transform: rotate(180deg)    ;
          transform:rotate(180deg);
          webkit-transform: rotate(180deg);
        }    
     }     
     li.a {
        display:none; 
        clear:both;
        background:#6b4bad;
        color:#fff;
     }     
   } 
}    


.profile .heart {
    position: absolute;
    bottom: 32px;
    left: 20px;
}

.radial-progress {   
    height: 60px !important;
   
}

/*dashboard*/



.appcontent .newsfeed ul {
  width:100%;   
  overflow: hidden;
  li {
    width:18%;   
  } 
}  

.appcontent .newsfeed .feed ul li {
   
    .taskstatus  {
     width:50%;     
   }     
}

.appcontent .tasks ul {
  padding-right:0 !important;   
} 

.appcontent .leaderboard .toplist table tr td {
    width:20%;
}

.appcontent .leaderboard .toplist table tr td:first-child {
   width:200px;   
} 

.appcontent .leaderboard .toplist {
   min-height: auto; 
}

.carousel {
    left: 50.5%;
    position: absolute;
    width: 120%;
    margin-left: -500px;
    overflow: hidden;
    height: 500px;
    top: -50px;
}

.controls {
    position: absolute;
    top: -30px;
    left: 5%;
    width: 90%;
    margin-left: 0px;
    z-index: 100;
}

.carousel-container {
    position: relative;
    margin-top: 20px;
    min-height: 400px;
}

#overlay .addvideo span.closebtn {
    display: block;
    width: 40px;
    height: 40px;
    right:0;
    background: url(../images/icon-closepopup.png);
    background-repeat: no-repeat;
    position: absolute;
     margin-left: 0px; 
    margin-top: -40px;
    cursor: pointer;
}

.appcontent .gallerygrid .photo div.caption {
    visibility: visible !important;
    opacity: .8;
}

.appcontent .galleryalbum .album .albumdetails p.readmore {
   margin-right:20px;   
}    

.appcontent .todaystask {
   
    margin-top: 0px; 
   
}

.appcontent .quizzes {
   padding:0;   
}

.appcontent .quizwrap .quizimage {
   float:none;
   margin-right:0px;
   img {
     width:100%;   
   } 
}    

.not-found {
   background:url(../images/empty.png);
   background-repeat: no-repeat;
  background-size: 100% 100%;
   width:80%;
   height:436px;
   margin:30px auto;
}    

.appcontent .faq h3 {
  margin-left: 30px;   
}
 
}    

@media (max-width: 980px) and (min-width: 768px) { 
    .actions {
  float:right;
  width:70%;
}
}    

@media (min-width: 1700px) {
   body.app {
   background:url(../images/bg.jpg);
   background-repeat: no-repeat;
   background-size: cover;
  }    
} 


@media (max-width: 767px) {
    
.desktoptablet {
   display:none;   
}  

.mobileonly {
  display:block;    
}    
    
 body {
 background:#5f4595 url(../images/bg.jpg);
 background-repeat: no-repeat;
background-size: cover;
}    
    
   header .logo {
     float:none;
     text-align: center;
     img:first-child {
       width:136px;
       height:auto;
     }  
     img.fullerlife {
       width:109px;
       height:auto;
     }   
  }
  
  .information {
      .col-md-6:first-child {
        float:right;
        .informationwrap {
          display: none;   
        }
        .countdown {
            /*margin-top: 560px;*/
            text-align: center;
        }
      }  
      .note {
        width: 80%;
        float: none;
        padding: 20px 25px 25px 25px;
        margin: 20px auto;
        background: #fff url(../images/box-rightbg.png);
        background-position: 98% 5px;
        background-repeat: no-repeat;
    }
    .login {
        background: #fff url(../images/box-rightbg.png);
        background-position: 98% 5px;
        background-repeat: no-repeat;
        width: 300px;
         margin: 20px auto;
      
    }
  }      
  
  .appcontent {
    margin-top: 0px;
}
  
  
  .primarycontent h1, .popcontent .content h1 {
       font-size: 20px;
}

.primarycontent .content h2, .popcontent .content .content h2 {
    font-size: 18px;
  
}
  
  .colright {
    position: absolute;
    float:left;
     top: 120px; 
    left: 0;
    width: auto;
    left: 0%;
    margin-left: -0px;     
   }
   
   .colrightlogin {
     position: relative;
     top:0;
     width:100%;
   } 
   
   .primarycontent h1, .popcontent .content h1 {
    padding-top: 20px;
}

.outerwrap header .logo {
    margin: 0;
    padding: 12px 0 0px 0;
    float: left;
    margin-top: 0px;
    width:50%;
    img {
      float:left;
      margin:5px 0 0 5px;
    }    
}

.outerwrap header .notificationsandprofile {
    float: right;
    padding: 10px 0 12px 10px;
    width:200px;
    margin:auto;
    width:50%;
    
    div.reminder {
    float: right;
    width: 50px;
    margin-top: 0px;
      span {
        display: block;
        float:right;
      }      
    }
   div.profile {
     padding-bottom: 20px;
    
   } 
}

.appcontent .days {
    margin-top: 20px;
    float: none; 
    text-align: center;
  
      ul {
        float: none; 
        display: inline-block;
        margin-bottom: 0px;
      }   
  
}

.appcontent h1 {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top:10px;
}   

.appcontent .currentmission {
    background: #fff;
    margin-top: 0px;
    padding-right: 8px;
    margin-left:20px;
    width: 100%;
}

.appcontent .box {
    margin-top:20px;
}

.appcontent .newsfeed {
  margin-top:-10px;  
  background:#e8e8e8;
  padding-bottom: 0;
}    

.appcontent .box h2.slogan {
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
}

.appcontent .box .dotodaystask {   
    padding-left: 20px;
}

.appcontent .taskcompletion {
    .taskcompletionwrap {
      background:#6b4bad; 
      overflow: hidden;
    }    
   background:#5f4595;
    margin-left: 0px;
     .profileimage {
       margin-left:15px;   
     }     
}



.appcontent .currentmission {
  margin-left:0;   
  background:#5f4595;
  div.mission {
    background:#fff;   
    margin-top:0;
  }  
  .points {
       background:#fff;  
       float:left;
       width:38%;
    h2 {
    font-size: 52px;
    text-align: center;
    font-weight: 800;
    }   
  }  
  .slider {
    background:#fff;
    width: 98%;
    padding-bottom: 20px;
   
    
    .bx-wrapper .bx-prev {
    left: -20px;
    top:120px;
   
    }
    
    .bx-wrapper .bx-next {
      right:-20px;   
       top:120px;
    }    
}
}  

.appcontent .taskcompletion .profileimage {
    margin-left: -12px;
    top:32px;
}

.radial-progress {
    top:-30px !important;
    height:50px !important;
}    

.appcontent .taskcompletion .level span.level {
   margin-right:0;   
}    

.appcontent {
  .newsfeed {
      h2 {
          float:none;
          font-size: 20px;
       } 
       ul {
        padding-left:0;   
        margin:10px 0 10px 0;
        padding-left:0;
       } 
       .feed {
        ul {
        
        li { 
            .feedimagetitle {
               float:left;
               width:80px;
               text-align: center;
                   background: url(../images/feedline.png);
                    background-repeat: no-repeat;
                    background-position: right;
               
            }    
            .feedimage {
                float: none;
                background:none;
                margin:auto;
                
            }
            .feedtitle {
                float: left;
                width: 68px;
                 background:none;
                 margin-top:0;
            }
            .taskpicture {
              display: none;   
            }   
            .taskstatus {
             
              width:50%;
            }    
        }
      }    
       }  
     
  }  
}    
 
.appcontent .weeks {
     float: none; 
     width: auto; 
     margin:auto;
     display: block;
     width:132px;
    text-transform: uppercase;
}

.appcontent .tasks ul li, .appcontent .quizzes ul li {
   float: left; 
 width: 50%; 
 }
 
  .appcontent .todaystask img {
       display: block;
       margin:auto;
  } 
 
 .appcontent .todaystask .taskdes {
  
    padding-left: 20px;
}

.appcontent .quizwrap {
  width:100%;
  .quizcontainer  {
     width:100%;    
  }  
} 

.appcontent .leaderboard .toplist h2 {
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 10px 20px 20px 20px;
    float: none;
    width: 100%;
}

.appcontent .cities {
    float: none;
    position: relative;
    margin-right: 10px;
    margin-left:20px;
    margin-top: 0px;
    margin-bottom: 10px;
    overflow: hidden;
}

 

.appcontent .leaderboard .toplist table tr td img {
  display: block;
  margin:auto;
}    

.appcontent .leaderboard .toplist table tr td span.rank {
    margin-left: 0px;
    margin-right: 0px;
    display: inline-block;
    font-size: 11px;
    text-align: center;
}


.appcontent .gamevault .col-md-4 {
    padding-right: 0px;
}

.appcontent .gamevault .col-xs-12 {
    width:97%;
}

.appcontent .fitterchoice .choice {
    float: none;
    width: 100%;
    background: #fff;
    padding-bottom: 20px;
    min-height: 320px;
}

.appcontent .fitterchoice .choiceanswer {
    float: none;
    width: 100%;
    background: #6b4bad;
    min-height: 320px;
}

.fiterchoicewrap .bx-wrapper .bx-next {
    right: 0px;
    top:45%;
}

.fiterchoicewrap .bx-wrapper .bx-prev {
    left: 10px;
    top:45%;
}

.appcontent .fitterchoice .choiceanswer h3 {
  margin-top:0px;
  padding-top:10px;
  font-size:20px;
  margin-bottom: 0;
  padding-bottom: 0;
}    

.appcontent .fiterchoicewrap .tasks h2 {
   margin-top:20px;   
}    

.appcontent .fitterchoice .choiceanswer .button {
    float: left;
    margin-left: 20px;   
    margin-top:10px;
}

.appcontent .fiterchoicewrap .bx-wrapper .bx-viewport {
  height:580px !important;   
}    

.appcontent .gallerysubnav ul li {
    float: left;
    padding: 8px 20px;    
    margin: 0 8px 20px 0;
    font-weight: 800;
    font-size: 14px;
  }

.loader.message .alertmessage {    
    top: 40%;
    width: 90%;
    background: #fff;
    margin: auto;
    text-align: left;
}

.profile {
  float:left;
  width:55%;
  position: relative;
  .star {
     position: absolute;
     bottom:12px;
     left:20px;
  }      
}    

.appcontent .taskcompletion .profilenameanddetails {
  width:40%;
  float:left;
  margin-top:10px;
  position: relative;
  .star {
    position: absolute;   
    right:10px;
    top:0px;
  } 
  h3 {
    padding-top:50px;   
  }      
}  

.appcontent .graph {
    text-align: center;
    width: 100%;
    margin: 40px auto;
}

#visualisation {
  width:100% !important;
  height:auto !important;
}   

.appcontent .newsfeed ul {
  display: block;   
}    

.appcontent .newsfeed ul li {
  width:20%;   
  border-right: 1px solid #dfdfdf;
}  

.appcontent .newsfeed .feed {
    height: auto;
    clear: both;
    margin-bottom: 0px;
    border-top: none;
    padding-top: 5px;
}

.appcontent .newsfeed .feed ul li {
    float: none;
    width: 100%;
    border: none;
    clear: both;
    padding-left: 0;
    background: #fff;   
    overflow: hidden;  
     padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-right:0;
    -webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
}

.appcontent .newsfeed .feed ul li .time {
  margin-top: 0px; 
}

.weeklywinnercol {
  background:#fff;
  overflow: hidden;
  margin-bottom: 20px;
  .profile {
      background:url(../images/hline-profile-mobile.png);
      background-repeat: no-repeat;
      background-position: right;
  } 
  h2 {
   font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;   
    margin-left:20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
    margin-right:20px;
  } 
  .profileimage {        
                margin: 0px auto auto auto;
                 overflow: hidden;
                border-radius: 50%;
                width: 65px;
                height: 65px;        
                border: 5px solid #fff;        
                   img {
                    display: inline;
                    margin: 0 auto;
                    height: 100%;
                  } 
           }
            h3 {
            font-weight: 800;
            font-size: 16px;
            text-transform: uppercase;
            margin:8px 0 0 0px;
            padding:0;
            text-align: center;
           
           } 
           .level {
             margin-top:10px;  
             text-align: center;
             margin-bottom: 20px;
             span {
                     background:#615182;  
                   padding:0 10px 2px 10px;
                   -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    border-radius: 8px;
                    color:#fff;
                    font-size: 11px;
                    font-weight: bold;
                  }      
           }  
           .points {
             float:right;
             text-align: center;
             width:50%;
             p {
               font-size: 25px;
               font-weight: 800;
               margin-top:20px;
             }   
             .ribbion {
                    background: url(../images/icon-ribbion-black.png);
                    background-repeat: no-repeat; 
                    background-size:13px 15px;
                    padding-left: 20px;
                    display: inline-block;
                  
                    p {
                    font-weight: normal;
                    font-size: 13px; 
                    margin-top:-2px;
                    }
             } 
           } 
} 

.weeklywinnercol.active { 
   margin-top:20px;  
   background:#6748a7;
    h2 {
       color:#fff;
       border-bottom: 1px solid #fff;
    } 
    h3 {
          color:#fff;
    } 
    .level span {
       background:#fff;
       color:#333;
    } 
    .points {
        p { color:#fff; }
     
      .ribbion {
                    background: url(../images/icon-ribbion-white.png);
                    background-repeat: no-repeat;  
             } 
   }
}

#overlay .addvideo {
    span.closebtn {
            display: block;
            width: 20px;
            height: 20px;
            background: url(../images/icon-closepopup.png);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            position: absolute;
            right:0;
            margin-left:0;
            margin-top: 10px;
            cursor: pointer;
        }
   .addvideo-content {
    background: transparent;
    padding: 30px 0px 0 0;
            
    }   
}

#overlay .addpic  {
    position: relative;
    span.closebtn {
            display: block;
            width: 20px;
            height: 20px;
            background: url(../images/icon-closepopup.png);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            position: absolute;
            right:0;
            margin-left:0;
            margin-top: 0px;
            cursor: pointer;
        }
  
}

.appcontent .gallerysubnav {
    margin-top: 20px;
    margin-right: 0px;
    float: none;
    width:100%;
    overflow: hidden;
    ul {
      margin:0;
      padding:0;
      li {
        float:right;   
      } 
      li:first-child {
        float:left;   
      }      
    }    
}

.appcontent .galleryalbum .album .albumdetails p.readmore {
   margin-right:20px;
}

.appcontent .box {
      min-height: auto;
}

.appcontent .box {    
   min-height: auto;
}

.appcontent .box .dotodaystask {
     min-height: auto;
}

.appcontent .query .queryform {
  padding-bottom: 20px;   
} 

.appcontent .taskcompletion h3 {
    font-size: 18px;
}

.appcontent .fitterchoice {
 
    margin-top: -20px;
}


.profile .addphoto {
    position: absolute;
    top: 12px;
    left: 140px;
}

.appcontent .tasks, .appcontent .quizzes,
.appcontent .tasks, .appcontent .quizwrap{
    padding-top: 20px;
  
}
 
 .appcontent .quizzes ul li {
     min-height: auto; 
 } 
    
.appcontent .quizzes ul li .quiz,
.appcontent .tasks  ul li .task{
    min-height: 200px;
} 

.appcontent .quizzes ul li .quiz {
    min-height: 230px;
}

.appcontent .quizzes ul li .quiz.done, .appcontent .tasks ul li .task.done {
     min-height: 200px;
}  

.appcontent .quizzes ul li .quiz.done {
   min-height: 230px;   
}

.appcontent .tasks ul li .task h3 {
  margin-top:20px;   
} 

.appcontent .leaderboard .profile {
    padding-top: 25px;
}


.appcontent .leaderboard .toplist {
   background:#e9e9e9;
}

.appcontent .leaderboard .toplist table {
  width:88%;
  tr {
    background:#fff;
    margin-bottom: 10px;
    td {
      padding: 8px 10px 8px 10px;   
    }    
  }
}

.appcontent .leaderboard {
    width: 100%;
    margin-top:20px;
}

.appcontent .leaderboard .toplist table {
   margin-left:18px;   
}

.appcontent .leaderboard .toplist table tr td:first-child {
    width: auto;
   
}

.appcontent .leaderboard .toplist table tr td {
    width: auto;
}

.appcontent .leaderboard .profile .profileimagewrap {
    float: none;
    width: 100%;
    background:none;
}

.appcontent .leaderboard .profile .pointswrap {
     float: none; 
    width: 100%; 
}

.appcontent .gallerygrid {
  margin-right:15px;
  height:auto !important;
}   

.appcontent .gallerygrid .photo div.caption {
   visibility: visible !important;
   opacity: .8;
}  

.appcontent .storyboard {
   margin-top:20px;
   article {
     padding:0;
     .story {
       h3 {
          clear:none; 
          float: left;
          display: inline-block;
          margin:0;
          padding:0 0 0 10px;
          position: relative;
          top:10px;
          text-transform: uppercase;
       }   
        p {          
           padding-left:10px;
        }
        p.meta {
           clear:both;           
        }
        p.readmore {
           margin-right:15px;   
        }    
     } 
   } 
}

.appcontent .storyboard article .articleimage {
    float: none;
    width: 100%;
    img {
      max-width: 100% !important;
	height: auto;
	width:100%;
    }    
}

.appcontent .storyboard article .story {
    float: none;
    width: 100%;
    margin-left: 0%;
}

.appcontent .storyboard article .story .share {
    float: right;
    padding-top:0px;
    ul {
      margin-top:10px;  
      li {
        font-size:18px;   
      } 
    }    
}

.appcontent .storyboard .fullarticle {
  width:100%;
  padding:0;
  margin:0;
  z-index: 100000;
} 

.appcontent .storyboard .fullarticle article {
    padding: 0;
    margin-left: 10%;
    margin-right: 10%;
    min-width: 80%;
    .story {
      width:98%;
      padding:0 0% 0 10px;
      h3 {
        clear:none;
        top:0;
        margin-top:10px;
      }      
      .content {
        width:100%;  
        height:auto;
      }
      .share {
        margin:0;
        float:right;
        width:50%;
        ul {
          margin-top:10px; 
          display: inline-block;
          float:right;
          margin-bottom: 0;
        }    
      }      
    }    
}

.appcontent .storyboard .fullarticle span.closepop {
    right: 5%;
    top:0%;
    img {
      width:20px;
      height:20px;
    }    
}

.appcontent .survey .col-md-7 .surverycontents {
   height:auto;   
}    

.appcontent .galleryalbum .album {
   padding:0;   
}  

.appcontent .galleryalbum .album .albumdetails  {
  margin-right:3%;   
}    
.row.taskrow {
  margin-top:20px;   
}

footer ul {
    float: none;    
}

.app footer ul {
    float: right;    
}

.appcontent .query .queryform .form-group.submit {
    text-align: left; 
}

.not-found {
   background:url(../images/empty.png);
   background-repeat: no-repeat;
  background-size: 100% 100%;
   width:80%;
   height:190px;
   margin:30px auto;
}   

/*Notifications*/
.outerwrap {
   header {
       .notificationsandprofile {
           div.reminder {
               .notificationswrap {
                   left:auto;
                   right:-10px;
                   top:30px;
                    width:440px;
                     background:url(../images/notification-toparrow.png);
                    background-repeat: no-repeat;
                    background-position: 100% 5%;
                   .notificationfeeds {
                      ul {
                        li {
                        clear:both;
                        overflow: hidden;
                          span {
                            float:none;   
                            display: inline-block;
                          }
                        }
                      }
                   }
               }
           }
           
       }
   }
}
    
}

 


@media (max-width: 500px) {
    .information {
      .col-md-6:first-child {
        
        
        .countdown {
           /* margin-top: 660px;*/
            text-align: center;
            #clock {
                width: 230px;
                margin: auto;
            }
        }
      }  
      
  }  
  
  .colrightlogin {
     position: relative;
     top:0;
     width:100%;
   } 
   
   .appcontent .taskcompletion .progresslabel span.star {
       right: 10px;
   
}

/*.appcontent .tasks, .appcontent .quizzes {
    padding-left:10px;
    padding-right:10px;
}*/

.appcontent .faq h3 {
    font-weight: 800;
    font-size: 20px;
    padding: 20px 0 10px 0px;
    margin:0 30px 0 30px;
    display: block;
}

  
  
  footer {
     .row .col-md-12 {
       margin-top: 10px;
       ul {
            li {
            float: none;
            color: #fff;
            font-size: 12px;
            border-right: none;
            padding-left: 10px;
            padding-right: 10px;
          }
       }    
     } 
      
  }  
  
  p.button a.btn {
    width: 130px;   
}

.outerwrap {
   header {
       .notificationsandprofile {
           div.reminder {
               .notificationswrap {
                   left:auto;
                   right:-10px;
                   top:30px;
                    width:340px;
                     background:url(../images/notification-toparrow.png);
                    background-repeat: no-repeat;
                    background-position: 100% 5%;
                   .notificationfeeds {
                      ul {
                        li {
                        clear:both;
                        overflow: hidden;
                          span {
                            float:none;   
                            display: inline-block;
                          }
                        }
                      }
                   }
               }
           }
           
       }
   }
}

.appcontent .currentmission .slider .bx-wrapper .bx-prev {
    left: -30px;
    top:130px;
   
    }
    
   .appcontent .currentmission .slider .bx-wrapper .bx-next {
      right:-40px;   
       top:130px;
    } 
   
}

@media (max-width: 440px) {
    .appcontent .newsfeed .feed ul li .taskstatus {
             
              width:40%;
            }   
}

@media (max-width: 350px) {
    .appcontent .days ul li {
       margin-right: 0px;
}


.appcontent .newsfeed .feed ul li .time {
   margin-left:0;
   
}

.appcontent .box h2.slogan {
    font-size: 18px;
}

p.button a.btn {   
    width: 90px;
}

.appcontent .mobile .faq ul.faq li.q span {
    float: left;
    width: 85%;
}

.appcontent .leaderboard .toplist table tr td span.level {
    padding: 0 5px 2px 5px;
    }
    
    .information .col-md-6:first-child .countdown {
    /*margin-top: 790px;    */
}


    
    .appcontent .newsfeed .feed ul li .taskstatus {
       width: 90px;
       padding-right:5px;

}

.appcontent .leaderboard .toplist table tr td {
    padding: 8px 10px 8px 10px;
    font-size:12px;
    text-align: center;
}

.outerwrap {
   header {
       .notificationsandprofile {
           div.reminder {
               .notificationswrap {
                   left:auto;
                   right:-10px;
                   top:30px;
                    width:300px;
                     background:url(../images/notification-toparrow.png);
                    background-repeat: no-repeat;
                    background-position: 100% 5%;
                   .notificationfeeds {
                      ul {
                        li {
                        clear:both;
                        overflow: hidden;
                          span {
                            display: inline-block;
                            float:left;
                            width:50%;
                          }
                          span:first-child {
                             width:30%;   
                          }
                        }
                      }
                   }
               }
           }
           
       }
   }
}

.appcontent .quizzes ul li .quiz {
    min-height: 231px;
}

.appcontent .quizzes ul li .quiz.done {
   min-height: 231px;   
}

.appcontent .currentmission .slider .bx-wrapper .bx-prev {
    right: -40px;
    top: 140px;
}

.appcontent .currentmission .slider .bx-wrapper .bx-next {
    right: -40px;
    top: 140px;
}
    
}    
  